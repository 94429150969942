import algoliasearch from 'algoliasearch/lite';

export const searchClient = algoliasearch(
  '5D8GUOZQR2',
  'a1b781c890836db7f6f238c1065f2f47'
);
// export const searchClient = algoliasearch(
//   'latency',
//   '6be0576ff61c053d5f9a3225e2a90f76'
// );

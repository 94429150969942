/** @jsxRuntime classic */
/** @jsx h */
import {
    AutocompleteComponents,
    AutocompletePlugin,
    getAlgoliaResults,
} from '@algolia/autocomplete-js';
import { SearchResponse } from '@algolia/client-search';
import { h } from 'preact';
import { useState } from 'preact/hooks';

import { Blurhash, StarIcon, FavoriteIcon } from '../components';
import { KRAKER_JOBS_INDEX_NAME } from '../constants';
import { searchClient } from '../searchClient';
import { ContentHit } from '../types';
import { cx } from '../utils';

export const jobsPlugin: AutocompletePlugin<ContentHit, {}> = {
    getSources({ query }) {
        if (!query) {
            return [];
        }

        return [
            {
                sourceId: 'jobsPlugin',
                getItems({ setContext }) {
                    return getAlgoliaResults<ContentHit>({
                        searchClient,
                        queries: [
                            {
                                indexName: KRAKER_JOBS_INDEX_NAME,
                                query,
                                params: {
                                    hitsPerPage: 4,
                                },
                            },
                        ],
                        transformResponse({ hits, results }) {
                            setContext({
                                nbContent: (results[0] as SearchResponse<ContentHit>).nbHits,
                            });

                            return hits;
                        },
                    });
                },
                onSelect({ setIsOpen }) {
                    setIsOpen(true);
                },
                templates: {
                    header({ state, Fragment }) {
                        return (
                            <Fragment>
                                <div className="aa-SourceHeaderTitle">
                                    Vacatures  voor {state.query}
                                </div>
                                <div className="aa-SourceHeaderLine" />
                            </Fragment>
                        );
                    },
                    item({ item, components }) {
                        return <JobItem hit={item} components={components} />;
                    },
                    footer({ state }) {
                        return (
                            state.context.nbJob > 4 && (
                                <div></div>
                                //<div style={{ textAlign: 'center' }}>
                                //  <a
                                //    href="https://example.org/"
                                //    target="_blank"
                                //    rel="noreferrer noopener"
                                //    className="aa-SeeAllBtn"
                                //  >
                                //    Zie alle pagina's ({state.context.nbTrailers})
                                //  </a>
                                //</div>
                            )
                        );
                    },
                },
            },
        ];
    },
};

function formatPrice(value: number, currency: string) {
    return value.toLocaleString('en-US', { style: 'currency', currency });
}

type JobItemProps = {
    hit: ContentHit;
    components: AutocompleteComponents;
};

function JobItem({ hit, components }: JobItemProps) {
    const [loaded, setLoaded] = useState(false);
    const [favorite, setFavorite] = useState(false);

    return (
        <a
            href={hit.link}
            rel="noreferrer noopener"
            className="aa-ItemLink aa-JobItem"
        >
            <div className="aa-ItemJob">
                <div
                    className={cx('aa-ItemPicture', loaded && 'aa-ItemPicture--loaded')}
                >
                    <div className="aa-ItemPicture--blurred">
                        {/*<Blurhash
              hash={hit.image_blurred}
              width={32}
              height={32}
              punch={1}
            />*/}
                    </div>
                    {/*<img
            src={hit.image}
            alt={hit.name}
            onLoad={() => setLoaded(true)}
          />*/}
                </div>

                <div className="aa-ItemJobBody">
                    <div>
                        {/*hit.brand && (
              <div className="aa-ItemJobBrand">
                {<components.Highlight hit={hit} attribute="brand" />}
              </div>
            )*/}
                        <div className="aa-ItemJobTitleWrapper">
                            <div className="aa-ItemJobTitle">
                                <components.Highlight hit={hit} attribute="name" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    );
}

/** @jsxRuntime classic */
/** @jsx h */
import {
  AutocompleteComponents,
  AutocompletePlugin,
  getAlgoliaResults,
} from '@algolia/autocomplete-js';
import { SearchResponse } from '@algolia/client-search';
import { h } from 'preact';
import { useState } from 'preact/hooks';

import { Blurhash, StarIcon, FavoriteIcon } from '../components';
import { KRAKER_CONTENT_INDEX_NAME } from '../constants';
import { searchClient } from '../searchClient';
import { ContentHit } from '../types';
import { cx } from '../utils';

export const contentPlugin: AutocompletePlugin<ContentHit, {}> = {
  getSources({ query }) {
    if (!query) {
      return [];
    }

    return [
      {
        sourceId: 'contentPlugin',
        getItems({ setContext }) {
          return getAlgoliaResults<ContentHit>({
            searchClient,
            queries: [
              {
                indexName: KRAKER_CONTENT_INDEX_NAME,
                query,
                params: {
                  hitsPerPage: 4,
                },
              },
            ],
            transformResponse({ hits, results }) {
              setContext({
                nbContent: (results[0] as SearchResponse<ContentHit>).nbHits,
              });

              return hits;
            },
          });
        },
        onSelect({ setIsOpen }) {
          setIsOpen(true);
        },
        templates: {
          header({ state, Fragment }) {
            return (
              <Fragment>
                <div className="aa-SourceHeaderTitle">
                  Pagina's voor {state.query}
                </div>
                <div className="aa-SourceHeaderLine" />
              </Fragment>
            );
          },
          item({ item, components }) {
            return <ContentItem hit={item} components={components} />;
          },
          footer({ state }) {
            return (
              state.context.nbContent > 4 && (
                <div></div>
                //<div style={{ textAlign: 'center' }}>
                //  <a
                //    href="https://example.org/"
                //    target="_blank"
                //    rel="noreferrer noopener"
                //    className="aa-SeeAllBtn"
                //  >
                //    Zie alle pagina's ({state.context.nbTrailers})
                //  </a>
                //</div>
              )
            );
          },
        },
      },
    ];
  },
};

function formatPrice(value: number, currency: string) {
  return value.toLocaleString('en-US', { style: 'currency', currency });
}

type ContentItemProps = {
  hit: ContentHit;
  components: AutocompleteComponents;
};

function ContentItem({ hit, components }: ContentItemProps) {
  const [loaded, setLoaded] = useState(false);
  const [favorite, setFavorite] = useState(false);

  return (
    <a
      href={hit.link}
      rel="noreferrer noopener"
      className="aa-ItemLink aa-ContentItem"
    >
      <div className="aa-ItemContent">
        <div
          className={cx('aa-ItemPicture', loaded && 'aa-ItemPicture--loaded')}
        >
          <div className="aa-ItemPicture--blurred">
              {/*<Blurhash
              hash={hit.image_blurred}
              width={32}
              height={32}
              punch={1}
            />*/}
          </div>
          {/*<img
            src={hit.image}
            alt={hit.name}
            onLoad={() => setLoaded(true)}
          />*/}
        </div>

        <div className="aa-ItemContentBody">
          <div>
            {/*hit.brand && (
              <div className="aa-ItemContentBrand">
                {<components.Highlight hit={hit} attribute="brand" />}
              </div>
            )*/}
            <div className="aa-ItemContentTitleWrapper">
              <div className="aa-ItemContentTitle">
                <components.Highlight hit={hit} attribute="name" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
}

/** @jsxRuntime classic */
/** @jsx h */
import { autocomplete } from '@algolia/autocomplete-js';
import { h, render } from 'preact';
import { pipe } from 'ramda';

import { createFillWith, uniqBy } from './functions';
//import { articlesPlugin } from './plugins/articlesPlugin';
//import { brandsPlugin } from './plugins/brandsPlugin';
//import { categoriesPlugin } from './plugins/categoriesPlugin';
//import { faqPlugin } from './plugins/faqPlugin';
//import { popularCategoriesPlugin } from './plugins/popularCategoriesPlugin';
import { popularPlugin } from './plugins/popularPlugin';
//import { productsPlugin } from './plugins/productsPlugin';
import { newsPlugin } from './plugins/newsPlugin';
import { jobsPlugin } from './plugins/jobsPlugin';
// import { testimonialPlugin } from './plugins/testimonialPlugin';
import { trailersPlugin } from './plugins/trailersPlugin';
import { contentPlugin } from './plugins/contentPlugin';
import { querySuggestionsPlugin } from './plugins/querySuggestionsPlugin';
import { quickAccessPlugin } from './plugins/quickAccessPlugin';
import { recentSearchesPlugin } from './plugins/recentSearchesPlugin';
import { cx, hasSourceActiveItem, isDetached } from './utils';

import '@algolia/autocomplete-theme-classic';
//import * as styles from './style.css';
import './style.css';


const removeDuplicates = uniqBy(({ source, item }) => {
  // const sourceIds = ['recentSearchesPlugin', 'querySuggestionsPlugin'];
  const sourceIds = ['querySuggestionsPlugin'];
  if (sourceIds.indexOf(source.sourceId) === -1) {
    return item;
  }

  return source.sourceId === 'querySuggestionsPlugin' ? item.query : item.label;
});

const fillWith = createFillWith({
  mainSourceId: 'querySuggestionsPlugin',
  limit: isDetached() ? 6 : 10,
});

const combine = pipe(removeDuplicates, fillWith);

autocomplete({
  container: '.js-search-autocomplete',
  detachedMediaQuery: '',
  placeholder: 'Waar ben je naar op zoek?',
  translations: {
    detachedCancelButtonText: 'sluiten'
  },
  autoFocus: false,
  openOnFocus: true,
  insights: true,
  plugins: [
    recentSearchesPlugin,
    querySuggestionsPlugin,
    trailersPlugin,
    contentPlugin,
    newsPlugin,
    // testimonialPlugin,
    jobsPlugin,
    //categoriesPlugin,
    //brandsPlugin,
    //faqPlugin,
    //productsPlugin,
    //articlesPlugin,
    popularPlugin,
    quickAccessPlugin,
    //popularCategoriesPlugin,
  ],
  reshape({ sourcesBySourceId, sources, state }) {
    const {
      recentSearchesPlugin: recentSearches,
      querySuggestionsPlugin: querySuggestions,
      //categoriesPlugin: categories,
      //brandsPlugin: brands,
      //faqPlugin: faq,
      popularPlugin: popular,
      //popularCategoriesPlugin: popularCategories,
      ...rest
    } = sourcesBySourceId;

    const sourceIdsToExclude = ['popularPlugin']; //, 'popularCategoriesPlugin'];
    const shouldDisplayPopularCategories = sources.every((source) => {
      if (sourceIdsToExclude.indexOf(source.sourceId) !== -1) {
        return true;
      }
      return source.getItems().length === 0;
    });

    return [
      // combine(recentSearches, querySuggestions), //, categories, brands, faq),
      combine(recentSearches, querySuggestions), //, categories, brands, faq),
      [
        !state.query && popular,
        ...Object.values(rest),
        false, //shouldDisplayPopularCategories, // && popularCategories,
      ].filter(Boolean),
    ];
  },
  renderNoResults({ state, render }, root) {
    {state.query && (
      <div className="aa-NoResultsQuery">
        Geen resultaten voor "{state.query}".
      </div>
    )}
  },
  render({ elements, state, Fragment }, root) {
    const {
      recentSearchesPlugin: recentSearches,
      querySuggestionsPlugin: querySuggestions,
      //categoriesPlugin: categories,
      //brandsPlugin: brands,
      //faqPlugin: faq,
      //productsPlugin: products,
      //articlesPlugin: articles,
      trailersPlugin: trailers,
      newsPlugin: news,
      jobsPlugin: jobs,
      // testimonalPlugin: testimonials,
      contentPlugin: content,
      popularPlugin: popular,
      quickAccessPlugin: quickAccess,
      //popularCategoriesPlugin: popularCategories,
    } = elements;

    const sourceIdsToExclude = ['popularPlugin']; //, 'popularCategoriesPlugin'];
    const hasResults =
      state.collections
        .filter(
          ({ source }) => sourceIdsToExclude.indexOf(source.sourceId) === -1
        )
        .reduce((prev, curr) => prev + curr.items.length, 0) > 0;

    render(
      <div className="aa-PanelLayout aa-Panel--scrollable">
        {!hasResults && (
          (state.query && (
            <div className="aa-NoResultsQuery" >
                {/*<Text id="results.noresults">Geen resultaten gevonden voor</Text> "{state.query}".*/}
                Geen resultaten gevonden voor "{state.query}".
            </div>
          ))
        )}

        <div className="aa-PanelSections">
          <div className="aa-PanelSection">
            {hasResults ? (
              (!state.query && recentSearches && (
                <Fragment>
                  <div className="aa-SourceHeader">
                    <span className="aa-SourceHeaderTitle">
                      Recente zoekopdrachten
                    </span>
                    <div className="aa-SourceHeaderLine" />
                  </div>
                  {recentSearches}
                </Fragment>
              )) ||
              (state.query && querySuggestions && (
                <Fragment>
                  <div className="aa-SourceHeader">
                      {/*<span className="aa-SourceHeaderTitle"><Text id="search.suggestions">Suggestions</Text></span>*/}
                      <span className="aa-SourceHeaderTitle">Suggesties</span>
                    <div className="aa-SourceHeaderLine" />
                  </div>

                  <div className="aa-PanelSectionSources">
                    {recentSearches}
                    {querySuggestions}
                    {/*{categories}*/}
                    {/*{brands}*/}
                    {/*{faq}*/}
                  </div>
                </Fragment>
              ))
            ) : (
              (state.query && (
                <div className="aa-NoResultsAdvices">
                  <ul className="aa-NoResultsAdvicesList">
                      {/*<li><Text id="search.doublecheck">Double-check your spelling</Text></li>*/}
                      {/*<li><Text id="search.fewer_keywords">Use fewer keywords</Text></li>*/}
                      {/*<li><Text id="search.less_specific">Search for a less specific item</Text></li>*/}
                      {/*<li><Text id="search.try_navigating">Try navigate using on the of the popular categories</Text></li>*/}
                  </ul>
                </div>
              ))
            )}
            {/* {!state.query && (
              <div className="aa-PanelSection--popular">{popular}</div>
            )} */}
          </div>
          <div className="aa-PanelSection">
            {content && (
              <div className="aa-PanelSection--content">
                <div className="aa-PanelSectionSource">{content}</div>
              </div>
            )}
            {trailers && (
              <div className="aa-PanelSection--trailers">
                <div className="aa-PanelSectionSource">{trailers}</div>
              </div>
            )}
            {news && (
              <div className="aa-PanelSection--content">
                <div className="aa-PanelSectionSource">{news}</div>
              </div>
            )}
            {jobs && (
              <div className="aa-PanelSection--content">
                <div className="aa-PanelSectionSource">{jobs}</div>
              </div>
            )}
            {/*{testimonials && (*/}
            {/*  <div className="aa-PanelSection--content">*/}
            {/*    <div className="aa-PanelSectionSource">{testimonials}</div>*/}
            {/*  </div>*/}
            {/*)}*/}
            
            {/*{products && (*/}
            {/*  <div className="aa-PanelSection--products">*/}
            {/*    <div className="aa-PanelSectionSource">{products}</div>*/}
            {/*  </div>*/}
            {/*)}*/}
            {/*{articles && (*/}
            {/*  <div className="aa-PanelSection--articles">*/}
            {/*    <div className="aa-PanelSectionSource">{articles}</div>*/}
            {/*  </div>*/}
            {/*)}*/}

            {/* {quickAccess && (
              <div
                className={cx(
                  'aa-PanelSection--quickAccess aa-PanelSection--zoomable',
                  hasSourceActiveItem('quickAccessPlugin', state) &&
                    'aa-PanelSection--active'
                )}
              >
                {quickAccess}
              </div>
            )} */}

            {/* {!hasResults && (
                <div
                className={cx(
                  'aa-PanelSection--popularCategories aa-PanelSection--zoomable',
                  hasSourceActiveItem('popularCategoriesPlugin', state) &&
                    'aa-PanelSection--active'
                )}
              >
                {popularCategories}
              </div>
            )} */}
          </div>
        </div>
      </div>,
      // </IntlProvider>,
      root
    );
  },
});

export const KRAKER_TRAILERS_INDEX_NAME = 'dev_trailer_nl';
export const KRAKER_CONTENT_INDEX_NAME = 'dev_content_nl';
export const KRAKER_TESTIMONIAL_INDEX_NAME = 'dev_testimonial_nl';
export const KRAKER_NEWS_INDEX_NAME = 'dev_news_nl';
export const KRAKER_JOBS_INDEX_NAME = 'dev_jobs_nl';
export const KRAKER_TRAILERS_QUERY_SUGGESTIONS_INDEX_NAME = 'dev_trailer_query_suggestions';
export const ALGOLIA_PRODUCTS_INDEX_NAME = 'autocomplete_demo_products';
export const ALGOLIA_PRODUCTS_QUERY_SUGGESTIONS_INDEX_NAME = 'autocomplete_demo_products_query_suggestions';
export const ALGOLIA_ARTICLES_INDEX_NAME = 'autocomplete_demo_articles';
export const ALGOLIA_FAQ_INDEX_NAME = 'autocomplete_demo_faq';
